$font-family: 'Montserrat','-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Oxygen-Sans','Ubuntu','Cantarell',Helvetica Neue,sans-serif;
$background-color: #fff;

$container-background-color: #FFF;
$container-shadow: 0px 0px 5px #fff;

$header-background-color: #FFF;
$header-text-color: #4A4A4A;
$header-text-hover-color: #CCC;
$navbar-height: 100px;
$header-max-width: 1200px;

$accent-background-color: #4d65a1;
$accent-text-color: #FFF;
$bid-box-background-color: $header-background-color;
$bid-box-text-color: $header-text-color;
@keyframes bidflash {
  0% {
    color: #059416;
  }
  60% {
    color: #03640e;
  }
  100% {
    color: #000;
  }
}

$lot-tile-active-background: #aac3d8;
$lot-tile-active-border: 1px solid #337ab7;

$watch-color: #7d7d7d;
$watch-hover-color: #229e22;
$watch-watched-color: #229e22;
$watch-watched-hover-color: #229e22;

$btn-watch-color: #ffffff;
$btn-watch-hover-color: #019e01;
$btn-watch-watched-color: #019e01;
$btn-watch-watched-hover-color: #ec543b;

$striped-row-even-background: #EEEEEE;
$striped-row-odd-background: #FFFFFF;
$striped-row-winning-background: #C5EFCD;

$tab-text-color: #000000;
$tab-active-text-color: #ffffff;
$tab-background: #21527d none repeat scroll 0% 0%;

$bar-background-color: #848484;
$bar-text-color: #FFF;

$footer-background-color: #f9f9f9;
$footer-text-color: #999;
$footer-border-top: 1px solid #e5e5e5;

$multipar-map-default-fill: #666;
$multipar-map-default-stroke: #333;
$multipar-map-default-stroke-width: 1;
$multipar-map-default-opacity: 0.6;
$multipar-map-selected-fill: #4b7aa5;
$multipar-map-selected-stroke: #132e46;
$multipar-map-selected-stroke-width: 1;
$multipar-map-selected-opacity: 0.6;
$multipar-map-winning-fill: #7bce8b;
$multipar-map-winning-stroke: #277936;
$multipar-map-winning-stroke-width: 1;
$multipar-map-winning-opacity: 0.6;

$invoice-bar-background: #EAEAEA;
$invoice-bar-color: #000;
$invoice-bar-border-top: 1px solid #CCC;
$invoice-bar-border-bottom: none;
$invoice-bar-border-left: none;
$invoice-bar-border-right: none;
