.flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
}

.flex-end {
  margin-top: auto;
}

.flex-grid { 
  display: flex; 
  flex-flow: row wrap; 
  justify-content: space-between; 

  .grid-item { 
    max-width: 30% !important; 
    img {width: 100%; height: auto; max-width: 350px; }

    .item-details {
      h3 {
        strong {font-weight: 700;}
      }
    }
  }
} 