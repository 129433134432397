
.title-container {
  background-color: #f5f5f5;
  border-radius:  2.5rem 0 2.5rem;
  padding: 3.5rem 4.5rem;
  margin-top: -6.5rem;
  margin-bottom: 2rem;

  h5 {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.3;
    text-transform: uppercase;
  }
}

// @media (max-width: 48em) {
//   .title-container {
//     margin-top: 10rem;
//   }
// }

.home-title {
  font-size: 3.125rem;
  text-transform: uppercase;
  margin-top: 0px;
  font-weight: bold;
}

.display-row {
  .auction-tile {
    display: flex;

    .auction-image {
      display: block;
      height: 200px;
      width: 250px;
      line-height: 200px;
      flex: 0 0 auto;
      border: 1px solid #ededed;
      text-align: center;

      img {
        vertical-align: middle;
        display: inline-block;
        max-height: 198px;
      }
    }
    @media (max-width: 520px) {
      flex-direction: column;
    }

    @media (max-width: 767.9px) {
      .auction-image {
        height: 120px;
        width: 150px;
        line-height: 120px;

        img {
          max-height: 118px;
        }
      }
    }

    .auction-flex {
      flex: 1 1 auto;
      display: inline-flex;
      flex-direction: column;

      .auction-actions {
        align-self: flex-end;
        white-space: nowrap;
      }
    }

    & + .auction-tile {
      margin-top: 5px;
      padding-top: 5px;
      border-top: 1px solid #6d6d6d;
    }
  }
}

.display-tile {
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px;
  box-sizing: border-box;
  width: 100%;

  .auction-tiles {
    width: 100%;
  }

  .auction-tile {
    padding: 0 15px;
    margin-bottom: 2rem;
    width: 33.3333333%;
    text-align: left;
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;

    transition: transform 250ms;

    // &:hover {
    //   transform: scale(1.05);
    //   z-index: 10000;
    // }

    .auction-flex {
      display: inline-flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    .auction-image {
      display: block;
      align-self: center;
      
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        width: 100%;
        height: 100%;
      }

      img {
        height: auto;
        max-width: 100%;
      }
    }
    .auction-details {
      display: flex;
      justify-content: space-between;
      // font-size: 12px;
      // line-height: 17px;
      color: #999;
      padding: 15px 25px;
      box-shadow: 0 -1px 1px #f9fafb, 0 1px 1px #e4eeee, 0 1px 2px #eef3f8;
      flex: 1 0 auto;

      h3 {
        margin-top: 0;
        font-size: 17px;
        text-transform: uppercase;
      }

      .auction-info {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        color: #595f68 !important;
        width: 100%;
      }

      .desc {
        margin-top: 10px;
      }

      .starts {
        font-size: 1.4rem;
        font-weight: 200;
        margin-bottom: 0;
      }
    }

    .auction-actions {
      .enter-auction {
        justify-self: flex-end;
        margin-right: 5px;
        border-radius: 4px 0 4px 0;
        // display: block;
      //   background-color: #000;
      //   color: #fff;
      //   text-decoration: none;
      //   text-transform: uppercase;
      //   padding-top: 10px;
      //   padding-bottom: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    .auction-tile {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    .auction-tile {
      width: 100%;
    }
  }
}

.full-width {
  width: 100%;
  position: relative;


  .grid-item {
    max-width: 18%;
    margin: 5px 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.img-background img { 
  width: auto; 
  min-width: 100%;
  object-fit: cover; 
}

.img-background .content {position: absolute; top: 10%; left: 10%; width: 40%;}

@media (max-width: 767px) {
  .img-background .content {
    top: 0;
    width: 40%;
  }

  .img-background img {
    object-fit: cover;
    min-width: 0;
    max-width: 100%;
  }
}

.round-div {
  background-color: #f5f5f5;
  border-radius: 2.5rem 0 2.5rem;
  padding: 3.5rem 4.5rem;
}

.overlap-50 { height: 100%; margin-top: -6rem; margin-left: 10%; margin-right: 10%; position: relative;  z-index: 1100;}

.overlap-50 h1 { margin: auto; }
