.lot-flex{
  display: flex;
  flex-flow: row wrap;
  
  &.tile-view{
    .item{
      display: flex;
      flex-direction: column;
      box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.75);
      margin-bottom: 40px;
      // margin-left: auto;
      margin-right: 15px;
      background: #ebebeb;
      transition: transform 350ms;
      box-sizing: border-box;

      &.newBid {
        .bidAmount {
          color: #059416;
          animation: 6s ease-out bidflash;
        }
      }
      
      &.blank{
        box-shadow: none !important;
        background: #fff;
      }
      &:hover {
        transform: scale(1.03);
        box-shadow: 3px 5px 5px 0px rgba(0,0,0,0.75);
        transition: box-shadow 0.5s ease-in-out
        
        
      }

      @media screen and (min-width: 768px){
        width: 47%;
        margin-bottom: 40px;
        &.featured_tile{
          width: 95%;
        }
      }
      @media screen and (min-width: 992px){
        width: 31%;
        &.featured_tile{
          width: 45%;
        }
      }
      @media screen and(min-width: 1600px){
        width: 23%;
      }
      &.featured_tile{
        .content{
          display: flex; 
          flex-direction: row;
        }
        .info_div{

        }
        .bid-box{
          display: flex; 
          flex-direction: row; 
          justify-content: space-between; 
          flex: 1 0 auto;
        }
      }
      .lot-picture{
        width: 100%;
      }
      .details{
        padding-left: 15px;
        padding-right: 15px;        
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .quick-bid-btn, .watch {
          border-radius: 4px 0 4px 0;
        }
      }
    }
    
    .item.winner {
      box-shadow: 0 0 10px 3px rgba(22,178,25,0.75);
    }

    .item.loser {
      box-shadow: 0 0 10px 3px rgba(178,19,16,0.75);
    }

  }
  &.list-view{
    .item{
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin-bottom: 10px;
      @media screen and (max-width: 768px){
        display: flex;
        flex-direction: column;
        margin: 1%;
        width: 48%;
      }
      .lot-picture{
        width: 260px;
        margin-right: 16px;
        @media screen and (max-width: 768px){
          margin-right: 0;
          width: 100%;
        }
      }
      .details{
        flex-grow: 1;
        @media screen and (max-width: 768px){
          width: 100%;
          flex-grow: 0;
        }
      }

    }
    
    .item.winner {
      box-shadow: 0 0 10px 3px rgba(22,178,25,0.75);
    }

    .item.loser {
      box-shadow: 0 0 10px 3px rgba(178,19,16,0.75);
    }
  }
}

.lot-button{
  background-color: #000000;
  border-radius: 0px;
  color: #ffffff;
}

