.search-container {
  padding: 1em 1em;
  background-color: #f5f5f5;
  border-radius: 0.675em 0 0.675em 0;

  .btn {
    border-radius: 4px 0 4px 0;
  }

  h1, label { 
    color: #3f4754;
  }

  .input-group {
    width: 100%;
  }
}

#searchResults {

  .item {
    width: calc(33.333333% - 15px);

    @media screen and (max-width: 768px){
      width: calc(100% - 15px);
    }
    @media screen and (min-width: 768px) and (max-width: 992px){
      width: calc(50% - 15px);
    }
  }

}

.auction-details-tile {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 0.675em 0 0.675em 0;

  .auction-image {
    padding: 10px;
  }

  .auction-flex {
    margin: 0 15px;
    justify-content: space-around;
    width: 100%;

    .description::before {
      content: '' !important;
    }
  }
}