@import './_variables';

.navbar {
  min-height: $navbar-height;
  margin-bottom: 0;
  border: none;
  border-radius: 0 !important;

  .container-fluid {
    max-width: $header-max-width;
  }

  .flex {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;

    nav {
      margin-left: auto;
    }
  }

  .nav
  {
    li {
      margin-left: 2rem;
    }

    a {
      font-size: 1.75rem;
      padding: .7rem 0.5rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.carousel {
  .navbar {
    margin-bottom: -$navbar-height;
    position: absolute;
    z-index: 9999;
    width: 100%;
    background-color: transparent;
  }
}

.navbar-brand {
  height: $navbar-height;
  img {
    height: auto;
    max-width: 100%;
  }
}

@media (max-width: 48em) {
  .carousel {
    .navbar-toggle {
      border-color: #fff;
      color: #fff;

      .icon-bar {
        background-color: #fff !important;
      }
    }
  }
}